



















import Reviews from '../mixins/Reviews'
import mixins from 'vue-typed-mixins'

export default mixins(Reviews).extend({
  name: 'Reviews1',
  data () {
    return {
    }
  }
})
